<template>
  <div class="card-list">
    <Card v-for="card in cards" 
          :key="card.id"
          :iconClass="card.iconClass"
          :title="card.title"
          :value="card.value"
          :change="card.change"
          :cardId="card.id" 
          @item-selected="handleItemSelected"
    />
  </div>
</template>

<script>
import Card from './card.vue'

export default {
  name: 'cardList',
  props: {
    ano: Number,
    cardList: {
      type: Object,
      required: true
    },
  },
  components: {
    Card
  },
  methods: {
    handleItemSelected(cardId, value) {
      this.$emit('item-selected', cardId, value);
    },
    async loadInfo() {
      this.cards[0].value = this.cardList['alunosNoSistema']['total_atual'];
      this.cards[0].change = `${this.cardList['alunosNoSistema']['percentual_mudanca']}% comparado ao ano de ${this.ano - 1}`;

      if (this.cardList['hasCalendarYearBefore'] > 0) {
        this.cards[1].value = this.cardList['alunosEmTurma']['total_atual'];
        this.cards[1].change = `${this.cardList['alunosEmTurma']['percentual_mudanca']}% comparado ao ano de ${this.ano - 1}`;


        this.cards[2].value = this.cardList['transferencias']['Transferido Externamente']['total_atual'];
        this.cards[2].change = `${this.cardList['transferencias']['Transferido Externamente']['percentual_mudanca']}% comparado ao ano de ${this.ano - 1}`;


        this.cards[3].value = this.cardList['transferencias']['Transferido Internamente']['total_atual'];
        this.cards[3].change = `${this.cardList['transferencias']['Transferido Internamente']['percentual_mudanca']}% comparado ao ano de ${this.ano - 1}`;

        this.cards[4].value = this.cardList['transferencias']['Evadido']['total_atual'];
        this.cards[4].change = `${this.cardList['transferencias']['Evadido']['percentual_mudanca']}% comparado ao ano de ${this.ano - 1}`;


        this.cards[5].value = this.cardList['notasAlunos']['total_atual'];
        this.cards[5].change = `${this.cardList['notasAlunos']['percentual_mudanca']}% comparado ao ano de ${this.ano - 1}`;

        this.cards[6].value = this.cardList['presencasAlunos']['total_atual'];
        this.cards[6].change = `${this.cardList['presencasAlunos']['percentual_mudanca']}% comparado ao ano de ${this.ano - 1}`;          
      }
    },
  },
  data() {
    return {
      cards: [
        {
          id: 1,
          iconClass: 'fa-user',
          title: `Novos Alunos Matriculados em ${this.ano}`,
          value: 0,
          change: ''
        },
        {
          id: 2,
          iconClass: 'fa-user',
          title: 'Alunos Ativos em Turma',
          value: 0,
          change: ''
        },
        {
          id: 3,
          iconClass: 'fa-user',
          title: 'Alunos Transferidos Externamente',
          value: 0,
          change: ''
        },
        {
          id: 4,
          iconClass: 'fa-user',
          title: 'Alunos  Transferidos Internamente',
          value: 0,
          change: ''
        },
        {
          id: 5,
          iconClass: 'fa-user',
          title: 'Alunos Evadidos',
          value: 0,
          change: ''
        },
        {
          id: 6,
          iconClass: 'fa-user',
          title: 'Médias Gerais Abaixo de 50 em Pelo Menos Uma Disciplina',
          value: 0,
          change: ''
        },
        {
          id: 7,
          iconClass: 'fa-user',
          title: 'Alunos com Percentual de Presença Abaixo de 75%',
          value: 0,
          change: ''
        },
        {
          id: 8,
          iconClass: 'fa-user',
          title: 'Rendimento Geral',
          change: ''
        },
        
      ]
    }
  },
  async beforeMount() {
    await this.loadInfo();
  },

}
</script>

<style scoped>
.card-list {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 16px;
  padding: 16px;
}

@media (max-width: 768px) {
  .card-list {
    grid-template-columns: 1fr;
  }
}
</style>