<template>
  <div>
    <div class="grid cor-painel">
      <div class="field col-12 sm:col-6 md:col-5 lg:col-4 xl:col-2">
        <label>Categoria </label>
        <select
          class="p-inputtext p-component"
          disabled
          v-model="categoriaSelectionada"
          style="appearance: revert !important; width: 100% !important"
        >
          <option v-for="info in listaCategoria" :value="info">
            {{ info.nome }}
          </option>
        </select>
      </div>

      <div v-if="categoriaSelectionada && categoriaSelectionada.nome == 'RENDIMENTO GERAL'"  class="field col-12 sm:col-6 md:col-6 lg:col-6 xl:col-3" style="padding-top: 7px;">
        <div>
          <label>Selecione o Segmento</label>
          <select
            @change="buscarDisciplinas();bimestreSelecionado = ''; buscarSeries(); serieSelecionada = ''; turmaSelecionada = ''; escolaSelecionada = ''"
            class="p-inputtext p-component"
            v-model="segmentoSelecionado"
            style="appearance: revert !important; width: 100% !important"
          >
            <option v-for="info in listaDeSegmentos" :value="info">
              {{ info.nome }}
            </option>
          </select>
        </div>
      </div>
      
      <div v-if="(categoriaSelectionada && categoriaSelectionada.nome != 'RENDIMENTO GERAL' || segmentoSelecionado != '') && listaTipos.length > 0" class="field col-12 sm:col-6 md:col-6 lg:col-6 xl:col-2">
        <label>Filtro</label>
        <select
          @change="buscarRelatorio(); bimestreSelecionado = ''"
          class="p-inputtext p-component"
          v-model="filtroSelectionado"
          style="appearance: revert !important; width: 100% !important"
        >
          <option v-for="info in listaTipos" :value="info">
            {{ info.nome }}
          </option>
        </select>
      </div>

      <div v-if="categoriaSelectionada.nome == 'RENDIMENTO GERAL' && filtroSelectionado.nome == 'POR ANO DE ESCOLARIDADE'" class="field col-12 sm:col-6 md:col-6 lg:col-6 xl:col-2">
        <label>Selecione a série</label>
        <select
          @change="bimestreSelecionado = ''"
          class="p-inputtext p-component"
          v-model="serieSelecionada"
          style="appearance: revert !important; width: 100% !important"
        >
          <option v-for="info in listaDeSeries" :value="info">
            {{ info.nome }}
          </option>
        </select>
      </div>
     

      
      <div v-if="(categoriaSelectionada && categoriaSelectionada.nome != 'RENDIMENTO GERAL' && filtroSelectionado != '' && !filtroSelectionado.nome.includes('POR ESCOLA') && !filtroSelectionado.nome.includes('POR SEGMENTO') && !filtroSelectionado.nome.includes('ABAIXO DO ESPERADO') && !filtroSelectionado.nome.includes('MÉDIA POR ESCOLA') && !filtroSelectionado.nome.includes('MÉDIA POR SEGMENTO')) ||  (categoriaSelectionada.nome == 'RENDIMENTO GERAL' && (filtroSelectionado.nome == 'POR ESCOLA' || filtroSelectionado.nome == 'POR TURMA')) " class="field col-12 sm:col-6 md:col-6 lg:col-6 xl:col-3">
        <label>Selecione a escola</label>
        <select
          @change="buscarRelatorio(); buscarTurmas(); bimestreSelecionado = ''"
          class="p-inputtext p-component"
          v-model="escolaSelecionada"
          style="appearance: revert !important; width: 100% !important"
        >
          <option v-for="info in listaDeEscolas" :value="info">
            {{ info.nome }}
          </option>
        </select>
      </div>

      <div v-if="(categoriaSelectionada && categoriaSelectionada.nome == 'RENDIMENTO GERAL' && filtroSelectionado.nome == 'POR TURMA' && escolaSelecionada != '') " class="field col-12 sm:col-6 md:col-6 lg:col-6 xl:col-1">
        <label>Selecione a turma</label>
        <select
          @change="bimestreSelecionado = ''"
          class="p-inputtext p-component"
          v-model="turmaSelecionada"
          style="appearance: revert !important; width: 100% !important"
        >
          <option v-for="info in listaDeTurmas" :value="info">
            {{ info.nome }}
          </option>
        </select>
      </div>

      <div v-if="categoriaSelectionada && categoriaSelectionada.nome == 'RENDIMENTO GERAL' && (filtroSelectionado.nome == 'REDE DE ENSINO' ||  (filtroSelectionado.nome == 'POR ESCOLA' && escolaSelecionada != '') || (filtroSelectionado.nome == 'POR TURMA' && turmaSelecionada != '') || (filtroSelectionado.nome == 'POR ANO DE ESCOLARIDADE' && serieSelecionada != ''))" class="field col-12 sm:col-6 md:col-6 lg:col-6 xl:col-2">
        <label>Selecione a disciplina</label>
        <select
          @change="exibirComponent = false; bimestreSelecionado = ''"
          class="p-inputtext p-component"
          v-model="disciplinaSelecionada"
          style="appearance: revert !important; width: 100% !important"
        >
          <option v-for="info in listaDeDisciplinas" :value="info">
            {{ info.nome }}
          </option>
        </select>
      </div>
      <div v-if="categoriaSelectionada && categoriaSelectionada.nome == 'RENDIMENTO GERAL' && (filtroSelectionado.nome == 'REDE DE ENSINO' || (filtroSelectionado.nome == 'POR ESCOLA' && escolaSelecionada != '') || (filtroSelectionado.nome == 'POR ANO DE ESCOLARIDADE' && serieSelecionada != '') || (filtroSelectionado.nome == 'POR TURMA' && turmaSelecionada != ''))" class="field col-12 sm:col-6 md:col-6 lg:col-6 xl:col-2">
        <div class="select-wrapper">
          <label for="media">Selecione o Período</label>
          <div class="select-button-container">
            <pm-MultiSelect
              @change="exibirComponent = true"
              v-model="bimestreSelecionado"
              :options="listaDeBimestres"
              :optionLabel="'nome'"
              :optionValue="'id'"
              placeholder="Selecione um ou mais"
              class="multi-select"
              style="background-color: #fff; height: 34px;"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="col-12">
    </div>
    <div v-if="exibirComponent">
      <div style="margin-left: 12px;" v-if="categoriaSelectionada.nome.includes('TRANSFERIDO') || categoriaSelectionada.nome == 'EVADIDOS' ">
        <InfoTransferidos :tipo="categoriaSelectionada.nome.toLowerCase()"  :filtro="filtroSelectionado" :ano="ano" :escola_id="escolaSelecionada.id" :nomeEscola="escolaSelecionada.nome"/>
      </div>
      <div style="margin-left: 12px;" v-if="categoriaSelectionada.nome.includes('ALUNOS ATIVOS EM TURMA')">
        <AlunosAtivo :tipo="categoriaSelectionada.nome.toLowerCase()"  :filtro="filtroSelectionado" :ano="ano" :escola_id="escolaSelecionada.id" :nomeEscola="escolaSelecionada.nome"/>
      </div>
      <div style="margin-left: 12px;" v-if="categoriaSelectionada.nome.includes('MÉDIA (NOTA) DOS ALUNOS')">
        <InfoNotas :tipo="categoriaSelectionada.nome.toLowerCase()"  :listaTotalAlunos="listaTotalAlunos"  :mediaNotaDaRedeDeEnsino="mediaNotaDaRedeDeEnsino"  :studentsBadGrades="studentsBadGrades" :filtro="filtroSelectionado" :ano="ano" :periodosSelecionados="periodosSelecionados" :escola_id="escolaSelecionada.id" :nomeEscola="escolaSelecionada.nome"/>
      </div>
      <div style="margin-left: 12px;" v-if="categoriaSelectionada.nome.includes('MÉDIA(PRESENÇAS) DOS ALUNOS')">
        <InfoPresencas :tipo="categoriaSelectionada.nome.toLowerCase()"  :mediaPresencaDaRedeDeEnsino="mediaPresencaDaRedeDeEnsino" :studentsBadAttendes="studentsBadAttendes" :filtro="filtroSelectionado" :ano="ano" :escola_id="escolaSelecionada.id" :nomeEscola="escolaSelecionada.nome"/>
      </div>
      <div style="margin-left: 12px;" v-if="categoriaSelectionada.nome.includes('RENDIMENTO GERAL')">
        <RendimentoFinal :tipo="categoriaSelectionada.nome.toLowerCase()" :filtro="filtroSelectionado" :ano="ano" :escola_id="escolaSelecionada.id" :nomeEscola="escolaSelecionada.nome" :segmento_id="segmentoSelecionado.id" :serie_id="serieSelecionada.id" :disciplina_id="disciplinaSelecionada.id" :turma_id="turmaSelecionada.id" :bimestres="bimestreSelecionado"/>
      </div>
    </div>
  </div>
</template>

<script>

import InfoTransferidos from '../infoFromCard/infoTransferidos.vue'
import AlunosAtivo from '../infoFromCard/alunosAtivos.vue'
import InfoNotas from '../infoFromCard/infoNotas.vue'
import InfoPresencas from '../infoFromCard/infoPresencas.vue'
import RendimentoFinal from '../infoFromCard/rendimentoFinal.vue'
import MediaRedeDeEnsino from '../helpers/mediaRedeDeEnsino.vue'

import categorias from '../categorias.json';
import { Escola } from "@/class/escolas";
import { SegmentoEscolar } from "@/class/segmentoEscolar";
import { Turma } from "@/class/turma";
import { SeriesEscolar } from "@/class/serie";


export default {
  data() {
    return { 
      categoriaSelectionada:  '',
      listaCategoria:  categorias,
      listaTipos:  [],
      listaDeEscolas: [],
      listaDeSegmentos: [],
      listaDeDisciplinas: [],
      listaDeSeries: [],
      listaDeTurmas: [],
      listaDeBimestres: [{id: 1, nome: '1º Periódo'}, {id: 2, nome: '2º Periódo'}, {id: 3, nome: '3º Periódo'}, {id: 4, nome: '4º Periódo'}],
      filtroSelectionado: '',
      escolaSelecionada: '',
      segmentoSelecionado: '',
      serieSelecionada: '',
      disciplinaSelecionada: '',
      bimestreSelecionado: '',
      turmaSelecionada: '',
      exibirComponent: false, 
    }
  },
  name: 'Filtro',
  props: {
    cardId: {
      type: Number,
      required: true
    },
    ano: {
      type: Number,
      required: true
    },
    valueCard : {
      type: Number,
      required: true
    },
    studentsBadGrades : {
      type: Object,
      required: true
    },
    studentsBadAttendes : {
      type: Object,
      required: true
    },
    mediaPresencaDaRedeDeEnsino : {
      type: Number,
      required: true
    },
    mediaNotaDaRedeDeEnsino : {
      type: Number,
      required: true
    },
    periodosSelecionados: {
      type: Array,
      required: true
    },
    listaTotalAlunos: {
      type: Number,
      required: true
    }
   
  },
  components: {
    InfoTransferidos, AlunosAtivo, InfoNotas, InfoPresencas, MediaRedeDeEnsino, RendimentoFinal
  },
  methods: {
    async buscarSeries() {
      SeriesEscolar.buscaPorSegmento(this.segmentoSelecionado.id).then((resp) => {
          this.listaDeSeries = resp.data;
        })
        .catch((err) => {
          const errorMessage = err.response.data.erro;
          this.$vaToast.init({
            message: errorMessage,
            iconClass: "fa-star-o",
            position: "top-right",
            color: "danger",
            duration: 2500,
            fullWidth: false,
          });
        })
        .finally(() => {
          
        });
    },
    async buscarRelatorio() {
      this.exibirComponent =  false;
      if (this.categoriaSelectionada.nome == 'RENDIMENTO GERAL') {
        return;
      }
      if (this.filtroSelectionado.nome.includes('POR ESCOLA') || this.filtroSelectionado.nome.includes('POR SEGMENTO')  || this.filtroSelectionado.nome.includes('ABAIXO DO ESPERADO') || this.escolaSelecionada != '') {
        this.exibirComponent =  true;
      }
    },
    async buscarEscolas() {
      Escola.obtemTodos()
          .then((resp) => {
            this.listaDeEscolas = resp.data;
          })
          .catch((err) => {
            const errorMessage = err.response.data.erro;
            this.$vaToast.init({
              message: errorMessage,
              iconClass: "fa-star-o",
              position: "top-right",
              color: "danger",
              duration: 2500,
              fullWidth: false,
            });
          })
          .finally(() => {
            
          });
    },
    async buscarSegmentos() {
      SegmentoEscolar.obtemTodos()
          .then((resp) => {
            this.listaDeSegmentos = resp.data.filter(seg => seg.id !== 5);
          })
          .catch((err) => {
            const errorMessage = err.response.data.erro;
            this.$vaToast.init({
              message: errorMessage,
              iconClass: "fa-star-o",
              position: "top-right",
              color: "danger",
              duration: 2500,
              fullWidth: false,
            });
          })
          .finally(() => {
            
          });
    },
    async buscarDisciplinas() {
      Turma.buscarDisciplinasBySegmento(this.segmentoSelecionado.id, this.ano)
          .then((resp) => {
            this.listaDeDisciplinas = resp.data;
            this.disciplinaSelecionada = this.listaDeDisciplinas[0];
          })
          .catch((err) => {
            const errorMessage = err.response.data.erro;
            this.$vaToast.init({
              message: errorMessage,
              iconClass: "fa-star-o",
              position: "top-right",
              color: "danger",
              duration: 2500,
              fullWidth: false,
            });
          })
          .finally(() => {
            
          });
    },
    async buscarTurmas() {
      if (this.categoriaSelectionada.nome == 'RENDIMENTO GERAL') {
        this.turmaSelecionada = "";
        Turma.showEscolaSegmentoTurma({ano: this.ano, escola_id: this.escolaSelecionada.id, segmento_id: this.segmentoSelecionado.id}).then((resp) => {
            this.listaDeTurmas = resp.data;
          })
          .catch((err) => {
            const errorMessage = err.response.data.erro;
            this.$vaToast.init({
              message: errorMessage,
              iconClass: "fa-star-o",
              position: "top-right",
              color: "danger",
              duration: 2500,
              fullWidth: false,
            });
          })
          .finally(() => {
            
          });
      }
      else {
        this.turmaSelecionada = "";
      }
      // Turma.buscarDisciplinasBySegmento(this.segmentoSelecionado.id, this.ano)
      //     .then((resp) => {
      //       this.listaDeDisciplinas = resp.data;
      //       this.disciplinaSelecionada = this.listaDeDisciplinas[0];
      //     })
      //     .catch((err) => {
      //       const errorMessage = err.response.data.erro;
      //       this.$vaToast.init({
      //         message: errorMessage,
      //         iconClass: "fa-star-o",
      //         position: "top-right",
      //         color: "danger",
      //         duration: 2500,
      //         fullWidth: false,
      //       });
      //     })
      //     .finally(() => {
            
      //     });
    },
  },
  async beforeMount() {
    await this.buscarEscolas();
    await this.buscarSegmentos();
    this.exibirComponent =  false;

    if (this.cardId == 2) {
      this.categoriaSelectionada = this.listaCategoria.find(x => x.nome == "ALUNOS ATIVOS EM TURMA");
      this.listaTipos = this.categoriaSelectionada.opcoes;
    }
    else if (this.cardId == 3) {
      this.categoriaSelectionada = this.listaCategoria.find(x => x.nome == "TRANSFERIDOS EXTERNAMENTE");
      this.listaTipos = this.categoriaSelectionada.opcoes;
    }
    else if (this.cardId == 4) {
      this.categoriaSelectionada = this.listaCategoria.find(x => x.nome == "TRANSFERIDOS INTERNAMENTE");
      this.listaTipos = this.categoriaSelectionada.opcoes;
    }
    else if (this.cardId == 5) {
      this.categoriaSelectionada = this.listaCategoria.find(x => x.nome == "EVADIDOS");
      this.listaTipos = this.categoriaSelectionada.opcoes;
    }
    else if (this.cardId == 6) {
      this.categoriaSelectionada = this.listaCategoria.find(x => x.nome == "MÉDIA (NOTA) DOS ALUNOS");
      this.listaTipos = this.categoriaSelectionada.opcoes;
      this.filtroSelectionado = { "nome": "MÉDIA FINAL ABAIXO DO ESPERADO" };
      this.exibirComponent =  true;
    }
    else if (this.cardId == 7) {
      this.categoriaSelectionada = this.listaCategoria.find(x => x.nome == "MÉDIA(PRESENÇAS) DOS ALUNOS");
      this.listaTipos = this.categoriaSelectionada.opcoes;
      this.filtroSelectionado = { "nome": "MÉDIA FINAL ABAIXO DO ESPERADO" };
      this.exibirComponent =  true;
    } 
    else if (this.cardId == 8) {
      this.categoriaSelectionada = this.listaCategoria.find(x => x.nome == "RENDIMENTO GERAL");
      this.listaTipos = this.categoriaSelectionada.opcoes;
    }    
  },
}
</script>

<style scoped>

</style>
