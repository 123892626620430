<template>
  <div class="stat-card">
    <h3 class="stat-title">{{ title }}</h3>
    <div class="stat-value">{{ value }}</div>
    <div v-if="percentage !== undefined" class="stat-percentage" :class="percentageColor">
      {{ percentage.toFixed(2) }}%
    </div>
  </div>
</template>

<script>
export default {
  name: 'StatCard',
  props: {
    title: {
      type: String,
      required: true
    },
    value: {
      type: Number,
      required: true
    },
    percentage: {
      type: Number,
      default: undefined
    },
    percentageColor: {
      type: String,
      default: 'text-blue-600'
    }
  }
}
</script>

<style scoped>
.stat-card {
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  padding: 20px;
  margin-bottom: 20px;
  width: calc(25% - 20px);
  margin-right: 20px;
}

.stat-title {
  font-size: 18px;
  color: #333;
  margin-bottom: 10px;
}

.stat-value {
  font-size: 24px;
  font-weight: bold;
  color: #000;
}

.stat-percentage {
  font-size: 14px;
  margin-top: 5px;
}

@media (max-width: 768px) {
  .stat-card {
    width: calc(50% - 20px);
  }
}

@media (max-width: 480px) {
  .stat-card {
    width: 100%;
    margin-right: 0;
  }
}
</style>