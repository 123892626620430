<template>
  <div class="info-ativos">
    <h1 class="page-title">Rendimento Geral</h1>
    <div class="stats-container">
      <StatCard 
        title="Total de Alunos"
        :value="dadosEstatisticos.total"
      />
      <StatCard 
        title="Com Rendimento"
        :value="dadosEstatisticos.comRendimento"
        :percentage="dadosEstatisticos.porcentagemComRendimento"
        percentageColor="text-green-600"
      />
      <StatCard 
        title="Sem Rendimento"
        :value="dadosEstatisticos.semRendimento"
        :percentage="dadosEstatisticos.porcentagemSemRendimento"
        percentageColor="text-red-600"
      />
      <StatCard 
        title="Sem Avaliação"
        :value="dadosEstatisticos.semDados"
        :percentage="dadosEstatisticos.porcentagemSemDados"
        percentageColor="text-yellow-600"
      />
    </div>

    <div style="display: flex; justify-content: center;">
      <div ref="chartRef" style="width: 1000px; height: 400px;"></div>
    </div>
  </div>
</template>

<script>
import { Estatisticas } from "@/class/estatisticas.js";
import SummaryCard from '../helpers/totalAlunos.vue'
import * as echarts from 'echarts';
import StatCard from './StatCard.vue'; 

export default {
  name: 'AlunosAtivos',
  props: {
    tipo: String,
    filtro: String,
    ano: Number,
    escola_id: Number,
    nomeEscola: String,
    segmento_id: Number,
    serie_id: Number,
    disciplina_id: Number,
    turma_id: Number,
    bimestres: String
  },
  components: {
    SummaryCard,
    StatCard
  },
  data() {
    return {
      dadosEstatisticos: {
        total: 0,
        comRendimento: 0,
        porcentagemComRendimento: 0,
        semRendimento: 0,
        porcentagemSemRendimento: 0,
        semDados: 0,
        porcentagemSemDados: 0
      },
      campos: [
        { key: 'categoria', label: 'Categoria' },
        { key: 'total', label: 'Total' },
        { key: 'percentagem', label: 'Percentagem' }
      ],
      tabelaDados: []
    }
  },
  watch: {
    bimestres() {
      this.getALL();
    } 
  },
  methods: {
    atualizarTabelaDados() {
      this.tabelaDados = [
        { categoria: 'Com Rendimento', total: this.dadosEstatisticos.comRendimento, percentagem: this.dadosEstatisticos.porcentagemComRendimento },
        { categoria: 'Sem Rendimento', total: this.dadosEstatisticos.semRendimento, percentagem: this.dadosEstatisticos.porcentagemSemRendimento },
        { categoria: 'Sem Avaliação',  total: this.dadosEstatisticos.semDados, percentagem: this.dadosEstatisticos.porcentagemSemDados }
      ];
    },
    createChart() {
      if (this.chart) {
        this.chart.dispose();
      }
      
      this.chart = echarts.init(this.$refs.chartRef);
      
      const option = {
        title: {
          text: 'Distribuição de Rendimento',
          left: 'center'
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          }
        },
        legend: {
          data: ['Total', 'Percentagem'],
          top: 'bottom'
        },
        xAxis: {
          type: 'category',
          data: ['Com Rendimento', 'Sem Rendimento', 'Sem Avaliação']
        },
        yAxis: [
          {
            type: 'value',
            name: 'Total',
            min: 0,
            max: Math.max(this.dadosEstatisticos.comRendimento, this.dadosEstatisticos.semRendimento, this.dadosEstatisticos.semDados),
            position: 'left',
            axisLabel: {
              formatter: '{value}'
            }
          },
          {
            type: 'value',
            name: 'Percentagem',
            min: 0,
            max: 100,
            position: 'right',
            axisLabel: {
              formatter: '{value}%'
            }
          }
        ],
        series: [
          {
            name: 'Total',
            type: 'bar',
            data: [
              this.dadosEstatisticos.comRendimento,
              this.dadosEstatisticos.semRendimento,
              this.dadosEstatisticos.semDados
            ],
            label: {
              show: true,
              position: 'inside',
              formatter: '{@score}',
              fontSize: 14,
              fontWeight: 'bold'
            }
          },
          {
            name: 'Percentagem',
            type: 'bar',
            yAxisIndex: 1,
            data: [
              this.dadosEstatisticos.porcentagemComRendimento,
              this.dadosEstatisticos.porcentagemSemRendimento,
              this.dadosEstatisticos.porcentagemSemDados
            ],
            label: {
              show: true,
              position: 'inside',
              formatter: '{@score}%',
              fontSize: 14,
              fontWeight: 'bold'
            }
          }
        ]
      };
      this.chart.setOption(option);
    },
    async getALL() {
      try {
        const estatisticas = await Estatisticas.obtemTodos(
          "rendimentoGeral",
          this.tipo,
          this.ano,
          this.filtro.nome,
          this.escola_id,
          null,
          this.segmento_id,
          this.turma_id,
          this.disciplina_id,
          this.bimestres,
          this.serie_id
        );
        this.dadosEstatisticos = estatisticas.data;
        this.atualizarTabelaDados(); 
        this.createChart();

        console.log(this.dadosEstatisticos);
      } catch (error) {
        console.error("Erro ao obter dados:", error);
        this.chartData = [];
        this.listaAlunos = [];
        if (this.chart) {
          this.chart.clear();
        }
      }
    },
  },
  mounted() {
    this.getALL();
  },
  beforeUnmount() {
    if (this.chart) {
      this.chart.dispose();
      this.chart = null;
    }
  }
}
</script>

<style scoped>
.info-ativos {
  padding: 20px;
}

.page-title {
  font-size: 24px;
  margin-bottom: 20px;
  text-align: center;
}

.stats-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin-bottom: 20px;
}

</style>