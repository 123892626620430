import axios from 'axios'

export class Estatisticas {
  static async obtemTodos(categoria, tipo, ano, filtro, escola_id, periodos, segmento_id, turma_id, disciplina_id, bimestre_id, serie_id) {
    if (ano == null) {
      ano = sessionStorage.getItem("anoSelecionado");
    }
    return axios.get(`/estatisticas?ano=${ano}&categoriaRelatorio=${categoria}&tipoRelatorio=${tipo}&filtro=${filtro}&escola_id=${escola_id}&periodos=${periodos}&segmento_id=${segmento_id}&turma_id=${turma_id}&disciplina_id=${disciplina_id}&bimestre_id=${bimestre_id}&serie_id=${serie_id}`);
  }
}
