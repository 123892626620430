<template>
  <div class="card">
    <div class="header-card">
      <h2 class="card-title">{{ title }}</h2>
    </div>
    <button class="transparent-button" @click="onItemSelected()">
      <div class="card-content">
        <i :class="['fa', iconClass]" aria-hidden="true"></i>
        <div class="card-value">
          {{ value }}
        </div>
        <div v-if="value == undefined">
          <p class="card-description">Indicadores de rendimento do aluno por disciplina</p>
        </div>
      </div>
    </button>
    <div class="footer-card">
      <div class="card-change">{{ change }}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Card',
  props: {
    iconClass: String,
    title: String,
    value: Number,
    change: String,
    cardId: Number,

  },
  methods: {
    onItemSelected() {
      if ((this.cardId == 2 || this.cardId == 3 || this.cardId == 4 || this.cardId == 5 || this.cardId == 6 || this.cardId == 7 || this.cardId == 8) && this.value != 0) {
        this.$emit('item-selected', this.cardId, this.value);
      }
    },
  }

}
</script>

<style scoped>

.card-description {
  text-align: end;
  font-size: 12px;
  font-weight: 200;
  font-style: italic;
}

.card {
  background-color: white;
  border-radius: 8px;
  padding: 16px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  display: flex;
  flex-direction: column;
  height: 100%;
}

.header-card{
  text-align: right;
  margin-bottom: 8px;
}

.card-title {
  font-size: 14px;
  margin: 0;
  color: #333;
}

.card-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-grow: 1;
}

.card-content .fa {
  font-size: 75px;
  color: #333;
  padding-left: 18px;
}

.card-value {
  font-size: 60px;
  font-weight: bold;
  color: #4285F4;
}

.transparent-button {
  background-color: transparent;
  border: none;
  color: inherit;
  font: inherit;
  padding: 0;
  cursor: pointer;
}

.footer-card{
  text-align: right;
  margin-top: 8px;
}

.card-change {
  font-size: 12px;
  font-style: italic;
  color: #FF0000;
}
</style>